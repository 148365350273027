<template>
  <div style="background: #294AFC;color: #000;height: 100%;">
    <van-nav-bar title="最新优惠套餐" :z-index="999" :fixed="true" left-arrow @click-left="back" />
    <div style="padding:46px 0px;background: #294AFC;">
      <img class="imgs" src="../../assets/img/zui1.png" alt />
      <div class="imgd" v-if="isok">
        <img class="imgs" src="../../assets/img/zui2.png" alt />
        <van-button
          class="bot"
          v-preventReClick
          @click="zhifu('2501','399','优惠套餐一','3')"
        >
          <span style="color: #000000;;">立即抢购</span>
        </van-button>
      </div>
      <div class="imgd" style="  margin: 0 10px;" v-else>
        <img class="imgs" src="../../assets/img/zui3.png" alt />
             <van-button
          class="bot"
          v-preventReClick
         @click="zhifu('2502','999','优惠套餐二','4')"
        >
          <span style="color: #000000;;">立即抢购</span>
        </van-button>
      </div>
    </div>
  </div>
</template>
<script>
import orderModel from "../../api/order";
import preventReClick from "../../assets/preventReClick";
export default {
  data() {
    return {
      isWxMini: "",
      shangjia: JSON.parse(sessionStorage.getItem("shangjia")),
      userInfo: JSON.parse(localStorage.getItem("userInfo")),
      chexin: JSON.parse(sessionStorage.getItem("chexin")),
      isok:this.$route.query.isok,
    };
  },
  methods: {
    zhifu(id,activityPrice,name,separateId) {
      var _this = this;
      if (
        this.chexin == null ||
        this.chexin.carNo == "" ||
        this.chexin.carNo == null
      ) {
        _this.$toast("请先认证车牌再购买！");
        setTimeout(() => {
          window.location.href = "/authentication";
        }, 1000);

        return;
      }
            var province = this.shangjia.province
        ? this.shangjia.province.toString()
        : "";
      var city = this.shangjia.city ? this.shangjia.city.toString() : "";
      var district = this.shangjia.district
        ? this.shangjia.district.toString()
        : "";
      var address = this.shangjia.address
        ? this.shangjia.address.toString()
        : "";
      var areaDetail = province + city + district + address;
      var lis = {
        id: id,
        activityPrice: activityPrice,
        separateId:separateId,//消费合伙人传1,88礼包穿2，399套餐传3，999套餐传4
        // activityPrice: 0.01,
        customerId: this.userInfo ? this.userInfo.customerId : "",
        gid: this.shangjia.id,
        payType: 1,
        type: 13,
        // shareId: this.shareId ? this.shareId : null,
        name:name,
        areaDetail:areaDetail, //维修厂详细地址
        carModel: this.chexin ? this.chexin.carBrand : "", //车型
        carNo: this.chexin ? this.chexin.carNo : "", //车牌
        garageName: this.shangjia.name, //修理厂名称
        positionJd: this.shangjia.positionJd,
        positionWd: this.shangjia.positionWd,
        tel: this.shangjia.legalPersonPhone,
        receiver: this.shangjia.legalPerson //用户名称
      };
      var isWxMini = window.__wxjs_environment === "miniprogram";
      console.log(isWxMini);
      if (isWxMini) {
        lis.token = localStorage.getItem("Authorization");
        lis.ccisok = 4; //3:会员卡订单 4:套餐订单 5:充值卡订单
        let payDataStr = JSON.stringify(lis);
        wx.miniProgram.navigateTo({
          url: "/pages/pay/pay?payDataStr=" + payDataStr // 微信小程序调用支付的页面
        });
      } else {
        orderModel.goumaivip(lis).then(res => {
          if (res.code == 200) {
            if (typeof WeixinJSBridge === "undefined") {
              // 微信浏览器内置对象。参考微信官方文档
              if (document.addEventListener) {
                document.addEventListener(
                  "WeixinJSBridgeReady",
                  _this.onBridgeReady(res.data),
                  false
                );
              } else if (document.attachEvent) {
                document.attachEvent(
                  "WeixinJSBridgeReady",
                  _this.onBridgeReady(res.data)
                );
                document.attachEvent(
                  "onWeixinJSBridgeReady",
                  _this.onBridgeReady(res.data)
                );
              }
            } else {
              console.log("准备调用微信支付");
              _this.onBridgeReady(res.data);
            }
          }
        });
      }
    },
    onBridgeReady(data) {
      console.log("调用微信支付WeixinJSBridge");
      var vm = this;
      WeixinJSBridge.invoke(
        "getBrandWCPayRequest",
        {
          // 下面参数内容都是后台返回的
          appId: data.appId, // 公众号名称，由商户传入
          timeStamp: data.timeStamp, // 时间戳
          nonceStr: data.nonceStr, // 随机串
          package: data.package, // 预支付id
          signType: data.signType, // 微信签名方式
          paySign: data.paySign // 微信签名
        },
        function(res) {
          // 使用以上方式判断前端返回,微信团队郑重提示：res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
          if (res.err_msg === "get_brand_wcpay_request:ok") {
            vm.$toast.success("支付成功");
            setTimeout(() => {
              window.location.href =
                "/order_details?id=" +
                data.id +
                "&status=" +
                3 +
                "&type=" +
                4 +
                "&rowtype=" +
                0 +
                "&isok=" +
                true;
              //   vm.$router.go(-1);
            }, 1000);
          } else {
            vm.$toast("支付失败");
          }
        }
      );
    },
    back() {
      this.$router.go(-1);
    }
  },
  created() {
    // this.onLoad();
    var isWxMini = window.__wxjs_environment === "miniprogram";
    this.isWxMini = isWxMini;
  }
};
</script>
<style lang="less" scoped>
.imgs {
  width: 100%;
  display: block;
}
.imgd {
  margin: 0 12px;
  position: relative;
  // margin-top: 0px;
}
.bot {
  position: absolute;
  right: 0;
  bottom: 0;
  background: #f6bb33;
  box-shadow: 0 3px 0 0 rgba(229, 109, 0, 0.74);
  border-radius: 4px;
  width: 128px;
  height: 40px;
  font-family: HYZhuZiMeiXinTiW;
  font-size: 18px;
  font-weight: bold;
  margin-right: 15px;
  margin-bottom: 23px;
  letter-spacing: 0;
}
</style>